<template>
    <section class="cream-background">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <h1>Order</h1>
                    <p class="mt-1">Order list </p>
                </div>
            </div>
        <div role="tablist" class="accordion basic-accordion">
              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <a href="javascript:void(0);" v-b-toggle.accordion1>
                    2021-12-07
                  </a>
                </b-card-header>
                <b-collapse id="accordion1" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-3">
                        <img class="img-fluid rounded" src="https://placehold.it/200x150" alt="image"> </div>
                      <div class="col-md-9">
                        <p>You can pay for the product you have purchased using credit cards, debit cards, or via online banking. </p>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
    </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'order',
    data: function(){
        return {
            order:null,
            
        }
    },
    methods:{
        getOrder(){
            axios.get('orders',{headers:{'X-AUTH-TOKEN':localStorage.getItem('token')} })
                .then(
                    resgetorder => {
                        console.log(resgetorder);
                        this.order =resgetorder.data
                    }
                )
                .catch(
                    errgetorder => {
                        console.log(errgetorder);
                    }
                )
        }
    },
    created(){
        this.getOrder()
    }
    
}
</script>
<style>

</style>